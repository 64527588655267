import {useForm} from "@inertiajs/react";

import helpPng from "/assets/help.png"
import {useTranslation} from "react-i18next";
const New = () => {
    const {t} = useTranslation()
    const {data, setData, post, processing, errors} = useForm({
        title: "",
        description: "",
        start_at: ""
    })

    const submit = (e) => {
        e.preventDefault()
        post('/travels')
    }

    return <>
        <form onSubmit={submit} className={'flex flex-col items-center mt-52 gap-6 mx-auto w-96'}>
            <label className="input input-bordered flex items-center gap-2 w-96">
                <input type="text"  value={data.title} onChange={(e) => setData('title', e.target.value)}
                       className="grow" placeholder={t("title")}/>
            </label>
            {errors.title && <div className={'mr-auto text-red-500'}>{errors.title}</div>}
            <div>
                <div className="tooltip cursor-help" data-tip={t("travel_description_help")}>
                    <img
                        src={helpPng}
                        alt="help"
                    />
                </div>
                <textarea value={data.description}
                          onChange={(e) => setData('description', e.target.value)}
                          placeholder={t("description")}
                          className="textarea textarea-bordered textarea-lg w-96"></textarea>
            </div>

            {errors.description && <div className={'mr-auto text-red-500'}>{errors.description}</div>}
            <div className={'flex justify-start items-center w-96'}>
                <h3 className={'mr-10'}>{t("travel_start_date")}:</h3>
                <input type="date" required value={data.start_at}
                       onChange={(e) => setData('start_at', e.target.value)}/>

            </div>
            {errors.start_at && <div className={'mr-auto text-red-500'}>{errors.start_at}</div>}
            <button className={'btn'} type="submit" disabled={processing}>{t("create")}</button>
        </form>
    </>
}

export default New
