import * as React from 'react';
import TravelCard from "../components/TravelCard.jsx";
import {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";

const Index = ({travels}) => {
    const {t} = useTranslation()
    const [loading, setLoading] = useState(false); //
    const [items, setItems] = useState(travels); // Хранит список путешествий
    const [offset, setOffset] = useState(0); // Начальное смещение
    const lastItemRef = useRef(null); // Реф для последнего элемента списка
    const observerRef = useRef(null); // Реф для хранения наблюдателя

    const loadMoreItems = async () => {
        if (loading) return; // Предотвращаем повторные запросы
        setLoading(true)

        const response = await fetch(`/travels/infinity_scroll?offset=${offset}`);
        const newItems = await response.json();


        if (newItems.length === 0) {
            if (observerRef.current) {
                observerRef.current.disconnect(); // Отписка от наблюдателя, если нет новых элементов
            }
        setLoading(false)
            return;
        }

        setItems(prevTravels => [...prevTravels, ...newItems]); // Объединяем старые и новые данные
        setLoading(false)
    };

    useEffect(() => {
        observerRef.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                loadMoreItems(); // Загружаем новые элементы, если последний элемент виден
            }
        });

        if (lastItemRef.current) {
            observerRef.current.observe(lastItemRef.current); // Подписываемся на последний элемент
        }

        return () => {
            if (observerRef.current) {
                observerRef.current.disconnect(); // Отписка при размонтировании компонента
            }
        };
    }, [lastItemRef.current]); // Указатель на текущий последний элемент

    useEffect(() => {
        // Обновляем наблюдателя, если список изменился
        if (lastItemRef.current && observerRef.current) {
            setOffset(offset + 20);
            observerRef.current.observe(lastItemRef.current);
        }
    }, [items]); // Указываем зависимость от travels, чтобы обновить наблюдатель при изменении списка

    return (
        <>

            {items.length > 0 ? <div className={'flex flex-col items-center'}>
                {items.map((travel, index) => <TravelCard ref={index === items.length - 1 ? lastItemRef : null } key={travel.id} travel={travel}/>)}
                {loading ? <span className="loading loading-spinner loading-lg"></span> : null}
                    </div> : <div className={'flex justify-center mt-60'}>{t("nothing_yet")} <br/>
                {t("be_first")}
                    </div>}
        </>
    )
}


export default Index
