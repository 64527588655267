import {useState} from "react";
import Register from "../components/Register.jsx";
import Login from "../components/Login.jsx";
import {useTranslation} from "react-i18next";

const New = () => {
    const {t} = useTranslation()
    const [activeTab, setActiveTab] = useState(0);

    const tabs = [
        {
            id: 0,
            label: t("sign_in"),
            content: <Login/>
        },
        {id: 1, label: t("sign_up"), content: <Register/>},
    ];

    return <>
        <div role="tablist" className="tabs tabs-boxed mx-auto w-96 mt-52">
            {tabs.map(tab => {
                return <a key={tab.id} role="tab" className={`tab ${activeTab === tab.id ? 'tab-active' : ""}`}
                          onClick={() => setActiveTab(tab.id)}>{tab.label}</a>
            })}
        </div>
        <div className={"mx-auto w-96 mt-8"}>
            {tabs[activeTab].content}
        </div>

    </>
}

export default New
