import {Link} from "@inertiajs/react";
import trashPng from "/assets/trash.png";
import {useTranslation} from "react-i18next";

const Index = ({notifications}) => {
    const {t} = useTranslation()
    return <>
        <div className={'flex flex-col items-center gap-4'}>
            {notifications.length === 0 ?
                <div className={'flex justify-center mt-60'}>{t("nobody_disturbed")}
                </div> : null}
            {notifications.map(notification => {
                const sentence = notification.body.split(" ")
                const name = sentence[0]
                const trip = sentence[sentence.length - 1]

                return <div key={notification.id} className="card bg-base-100 w-96 shadow-md mb-4">
                    <div className="card-body">
                        <div className={"flex justify-between"}>
                            <h2 className="card-title">{notification.title}</h2>
                            {notification.method === "join" || notification.method === "reject" ? <Link href={`/notifications/${notification.id}`} method='delete' as='button'> <img src={trashPng}
                                                                                                                                                 alt="delete travel"/></Link> : ""}
                        </div>
                        <p className={'mt-2'}>
                            <Link href={`/users/${notification.sender_id}`} className={'link link-info'}>{`${name.charAt(0).toUpperCase()}${name.slice(1)} `}</Link>
                            {sentence.slice(1, sentence.length - 1).join(" ")}
                            <Link href={`/travels/${notification.travel_id}`} className={'link link-info'}>{" " + trip.slice(0, trip.length - 1)}</Link>
                            .
                        </p>
                        {notification.method === "request" ? <div className={'flex justify-evenly mt-4'}>
                            <Link href={'/memberships/join'} className={'btn btn-secondary'} method={'post'}
                                  as={'button'} data={{
                                travel_id: notification.travel_id,
                                user_id: notification.sender_id,
                                notification_id: notification.id
                            }}>{t("accept")}</Link>
                            <Link href={'/memberships/reject'} className={'btn btn-accent'} method={'post'}
                                  as={'button'} data={{
                                travel_id: notification.travel_id,
                                user_id: notification.sender_id,
                                notification_id: notification.id
                            }}>{t("reject")}</Link>
                        </div> : ""}
                    </div>
                </div>
            })}
        </div>
    </>
}

export default Index
