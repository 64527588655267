import EmptyLayout from "./layouts/EmptyLayout.jsx";
import {useTranslation} from "react-i18next";

const error = ({ status }) => {
    const {t} = useTranslation()
    const title =
        {
            503: t("service_unavailable"),
            500: t("server_error"),
            404: t("page_not_found"),
            403: t("forbidden"),
        }[status] || t("unexpected_error")

    const description = {
        503: t("service_unavailable_description"),
        500: t("server_error_description"),
        404: t("page_not_found_description"),
        403: t("forbidden_description"),
    }[status]

    return (
        <div className={'flex flex-col items-center mt-52'}>
            <h1 className={'mb-4'}>
                {status}: {title}
            </h1>
            <div>{description}</div>
        </div>
    )
}

error.layout = (page) => <EmptyLayout children={page} />

export default error
