import {Link, usePage} from "@inertiajs/react";
import calculateAge from "../../helpers/date.js";
import {useTranslation} from "react-i18next";

const show = ({travel, user}) => {
    const {t} = useTranslation()
    const date = new Date(travel.start_at);
    const {current_user, locale} = usePage().props;

    return (
        <div className={'flex flex-col items-center'}>
            <div className="card bg-base-100 w-96 shadow-md mb-4">
                <div className="card-body">
                    <h2 className="card-title">{travel.title}</h2>
                    <p className={'break-words'}>{travel.description}</p>
                    <div className="card-actions justify-between items-center">
                <span>{t("travel_start_date")} {date.toLocaleString(locale !== 'ru' ? 'en-EN' : "ru-RU", {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit'
                })}</span>
                    </div>
                </div>
            </div>
            <div className="card bg-base-100 w-96 shadow-md mt-8">
                <div className="card-body">
                    <div className={'flex justify-between'}>
                        <Link href={`/users/${travel.user_id}`}>
                            {user.photo_url ? <div className="avatar">
                                <div className="w-24 rounded-full">
                                    <img src={user.photo_url}/>
                                </div>
                            </div> : <div className="avatar placeholder">
                                <div className="bg-neutral text-neutral-content w-24 rounded-full">
                                    <span
                                        className="text-xs">{user.first_name.charAt(0) + " " + user.last_name.charAt(0)}</span>
                                </div>
                            </div>}
                        </Link>
                        <div className={'flex flex-col'}>
                            <Link className={'link link-info'} href={`/users/${travel.user_id}`}>
                                <h2 className={'text-3xl font-bold leading-10'}>{user.first_name}</h2>
                                <h2 className={'text-3xl font-bold'}>{user.last_name}</h2>
                            </Link>
                        </div>
                    </div>
                    <div className={'flex justify-evenly items-center w-full'}>
                        <h2 className={'text-1xl'}>{user.gender === 'man' ? t("man") : t("woman")}</h2>
                        <h2 className={'text-1xl text-sky-400'}>@{user.handle}</h2>
                        <h2 className={'text-1xl'}>{calculateAge(user.birthday)} {t("years_old")}</h2>
                    </div>
                </div>
            </div>
            {current_user === null || current_user?.id === user?.id ? "" : <button className="btn mt-8">{t("join")}</button>}
        </div>
    )
}

export default show
