const calculateAge = (birthDate) => {
    const today = new Date(); // Текущая дата
    const birth = new Date(birthDate); // Дата рождения

    let age = today.getFullYear() - birth.getFullYear(); // Вычисляем разницу в годах
    const monthDifference = today.getMonth() - birth.getMonth(); // Разница в месяцах

    // Если текущий месяц меньше месяца рождения или
    // если месяц совпадает, но текущий день меньше дня рождения, уменьшаем возраст на 1
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birth.getDate())) {
        age--;
    }

    return age;
}

export default  calculateAge;
