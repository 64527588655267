import {useTranslation} from "react-i18next";
import {useState} from "react";
import arrowDown from '/assets/arrow_down.png'
import arrowUp from '/assets/arrow_up.png'

import {enqueueSnackbar, SnackbarProvider} from "notistack";

const ChangeLanguage = () => {
    const {i18n} = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const {t} = useTranslation()

    const toggleCollapse = () => {
        setIsOpen(!isOpen);
    };

    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);
        (async () => {
           const result = await fetch("/users/change_locale", {
               method: 'PATCH', // Указываем метод PATCH
               headers: {
                   'Content-Type': 'application/json',
                   'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
               },
               body: JSON.stringify({locale: lang}), // Преобразуем объект данных в JSON
           })

            if(result.status === 200) {
                enqueueSnackbar(t("something_wrong"), {
                    autoHideDuration: 3000,
                    variant: "warning"
                })
            }

        })()
        setIsOpen(false);
    };

    return <div className={'w-full flex flex-col'} onClick={toggleCollapse}>
        <div className="w-full flex justify-between items-center">
            <span>{t("language")}</span>
            <img src={isOpen ? arrowUp : arrowDown} alt="arrow"/>
        </div>

        {isOpen && (
            <>
                <button
                    onClick={() => changeLanguage('en')}
                    className="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
                >
                    English
                </button>
                <button
                    onClick={() => changeLanguage('ru')}
                    className="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
                >
                    Русский
                </button>
            </>
        )}
    </div>
}

export default ChangeLanguage
