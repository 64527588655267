import {useForm} from "@inertiajs/react";
import handlePng from '/assets/handle.png'
import userPng from '/assets/user.png'
import womanPng from '/assets/woman.png'
import manPng from '/assets/man.png'
import EmptyLayout from "../layouts/EmptyLayout.jsx";
import passwordPng from "/assets/password.png";
import {useTranslation} from "react-i18next";
const Edit = ({user}) => {
    const {t} = useTranslation()
    const {data, setData, patch, processing, errors, progress, transform} = useForm({
        photo: '',
        first_name: user.first_name,
        last_name: user.last_name,
        about: user.about,
        handle: user.handle,
        gender: user.gender,
        birthday: user.birthday,
        password: '',
        new_password: ''
    })

    const submit = (e) => {
        let gender = ''
        if (user.gender === "man") {
            gender = data.gender === 'on' ? 0 : 1
            gender = data.gender === 'off' ? 1 : 0
        } else {
            gender = data.gender === 'off' ? 0 : 1
            gender = data.gender === 'on' ? 1 : 0
        }

        e.preventDefault()
        transform((data) => ({
            ...data,
            gender: gender
        }))
        patch(`/users/${user.id}`)
    }

    return <>
        <form onSubmit={submit} className={'flex flex-col items-center mt-52 gap-6 mx-auto w-96'}>
            {user.photo_url ? <div className="avatar">
                <div className="w-24 rounded-full">
                    <img src={data.photo !== '' && ["image/jpeg", "image/png", "image/webp"].includes(data.photo.type) ? URL.createObjectURL(data.photo) : user.photo_url}/>
                </div>
            </div> : <div className="avatar placeholder">
                <div className="bg-neutral text-neutral-content w-24 rounded-full">
                    <span className="text-xs">{user.first_name.charAt(0) + " " + user.last_name.charAt(0)}</span>
                </div>
            </div>}
            {errors.photo && <div className={'mr-auto text-red-500'}>{errors.photo}</div>}
            <label className="input input-bordered flex items-center gap-2 w-96">
                <img
                    src={userPng}
                    alt="first name"
                />
                <input type="text" value={data.first_name} onChange={(e) => setData('first_name', e.target.value)}
                       className="grow" placeholder={t("first_name")}/>
            </label>
            {errors.first_name && <div className={'mr-auto text-red-500'}>{errors.first_name}</div>}
            <label className="input input-bordered flex items-center gap-2 w-96">
                <img
                    src={userPng}
                    alt="last name"
                />
                <input type="text" value={data.last_name}
                       onChange={(e) => setData('last_name', e.target.value)} className="grow" placeholder={t("last_name")}/>
            </label>
            {errors.last_name && <div className={'mr-auto text-red-500'}>{errors.last_name}</div>}
            <label className="input input-bordered flex items-center gap-2 w-96">
                <img
                    src={handlePng}
                    alt="at symbol"
                />
                <input type="text" value={data.handle}
                       onChange={(e) => setData('handle', e.target.value)} className="grow"
                       placeholder={t("handle")}/>

            </label>
            {errors.handle && <div className={'mr-auto text-red-500'}>{errors.handle}</div>}
            <label className="input input-bordered flex items-center gap-2 w-96">
                <img
                    src={passwordPng}
                    alt="password"
                />
                <input type="password" value={data.password}
                       onChange={(e) => setData('password', e.target.value)} className="grow"
                       placeholder={t("old_password")}/>

            </label>
            {errors.password && <div className={'mr-auto text-red-500'}>{errors.password}</div>}
            <label className="input input-bordered flex items-center gap-2 w-96">
                <img
                    src={passwordPng}
                    alt="new password"
                />
                <input type="password" value={data.new_password}
                       onChange={(e) => setData('new_password', e.target.value)} className="grow"
                       placeholder={t("new_password")}/>

            </label>
            {errors.new_password && <div className={'mr-auto text-red-500'}>{errors.new_password}</div>}
            <textarea value={data.about}
                      onChange={(e) => setData('about', e.target.value)}
                      placeholder={t("about")}
                      className="textarea textarea-bordered textarea-lg w-96"></textarea>
            {errors.about && <div className={'mr-auto text-red-500'}>{errors.about}</div>}
            <input type="file" className="file-input w-full max-w-xs"
                   onChange={(e) => setData('photo', e.target.files[0])}/>
            <div className={'flex justify-start items-center w-96 '}>
                <h3 className={'mr-32'}>Пол:</h3>
                <label className="swap swap-flip text-9xl">
                    <input type="checkbox"
                           onChange={(e) => setData('gender', e.target.value)}/>

                    {user.gender === "man" ? (
                        <>
                            <div className="swap-off">
                                <img src={manPng} alt="man"/>
                            </div>
                            <div className="swap-on">
                                <img src={womanPng} alt="woman"/>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="swap-on">
                                <img src={manPng} alt="man"/>
                            </div>
                            <div className="swap-off">
                                <img src={womanPng} alt="woman"/>
                            </div>
                        </>
                    )}

                </label>
            </div>
            <div className={'flex justify-start items-center w-96'}>
                <h3 className={'mr-10'}>{t("birthday")}:</h3>
                <input type="date" value={data.birthday}
                       onChange={(e) => setData('birthday', e.target.value)}/>

            </div>
            {errors.birthday && <div className={'mr-auto text-red-500'}>{errors.birthday}</div>}
            {progress && (
                <progress value={progress.percentage} max="100">
                    {progress.percentage}%
                </progress>
            )}
            <button className={'btn'} type="submit" disabled={processing}>{t("refresh")}</button>
        </form>
    </>
}

Edit.layout = (page) => <EmptyLayout children={page}/>

export default Edit
