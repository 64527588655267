import {Link, router, usePage} from "@inertiajs/react";

import {MeiliSearch} from 'meilisearch'
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import ChangeLanguage from "./ChangeLanguage.jsx";

const client = new MeiliSearch({
    host: 'https://toget.space/meilisearch',
    apiKey: 'd37IhsAQvK-qB3gqxiszlkNACbSu1zzp61zYlGcGWY4',
})


const Navbar = () => {
    const {t} = useTranslation();
    const [searchValue, setSearchValue] = useState("")
    const [isFocused, setIsFocused] = useState(false)
    const [travelHits, setTravelHits] = useState([])
    const [userHits, setUserHits] = useState([])
    const {current_user, notification_count} = usePage().props

    useEffect(() => {
        if (searchValue !== "") {
            (async () => {
                const res = await client.multiSearch({
                    queries: [
                        {q: searchValue, indexUid: "Travel", sort: ["start_at:asc"]},
                        {q: searchValue, indexUid: "User"}
                    ]
                })
                const hits = res.results
                setTravelHits(hits[0].hits)
                setUserHits(hits[1].hits)
            })()
        }
    }, [searchValue]);

    return <>
        {isFocused ? (
            <>
                <div
                    className="fixed inset-0 bg-black opacity-50 z-10"
                    onClick={() => setIsFocused(false)}
                />
                <div
                    className={'absolute rounded-lg inset-x-1 top-16 w-11/12 sm:w-10/12 md:w-4/6 xl:w-3/6 2xl:w-2/6 h-2/4 bg-white z-20 mx-auto overflow-y-auto gap-2 pt-6 pb-6'}>
                    {userHits.map(user => <div onClick={() => {
                        setSearchValue("")
                        setUserHits([])
                        setTravelHits([])
                        router.visit(`/users/${user.id}`)
                        setIsFocused(false)
                    }} className={'hover:bg-gray-100 h-14 cursor-pointer'}
                                                   key={user.id}>
                        <div className={"ml-2 xl:ml-14"}>
                           <span className={'link-info'}>@{user.handle}</span> - {user.first_name + " " + user.last_name}
                        </div>
                    </div>)}
                    {travelHits.map(travel => <div onClick={() => {
                        setSearchValue("")
                        setUserHits([])
                        setTravelHits([])
                        router.visit(`/travels/${travel.id}`)
                        setIsFocused(false)
                    }} className={'hover:bg-gray-100 h-14 cursor-pointer'}
                                             key={travel.id}>
                        <div className={"ml-2 xl:ml-14"}>
                            {travel.title} - {travel.user.first_name + " " + travel.user.last_name} - {t("departure")}: {travel.start_at}
                        </div>
                    </div>)}
                </div>
            </>
        ) : null}
        <div className="navbar bg-base-100 grid grid-cols-5 mb-8 z-20">
            <Link href='/' className="text-xl col-span-1">Toget</Link>
            <div className="form-control z-20 col-span-3">
                <input type="text" onFocus={() => setIsFocused(true)} value={searchValue}
                       onChange={(e) => setSearchValue(e.target.value)} placeholder={t("search")}
                       className="input input-bordered w-full sm:w-96"/>
            </div>
            <div className="flex justify-between gap-2 col-span-1">
                {current_user ? <div className="dropdown dropdown-end">
                    <div tabIndex={0} role="button" className="btn btn-ghost btn-circle avatar relative">
                        <div className="w-10 rounded-full">
                            <img
                                alt="User avatar"
                                src={current_user.photo_url}
                            />
                        </div>
                        {notification_count > 0 ?
                            <div className="badge badge-secondary w-6 h-6 absolute -top-1 -right-1">
                                {notification_count}
                            </div> : ""}
                    </div>

                    <ul
                        tabIndex={0}
                        className="menu menu-sm dropdown-content bg-base-100 rounded-box z-[1] mt-3 w-52 p-2 shadow">
                        <li><Link href={`/users/${current_user.id}`}>{t("profile")}</Link></li>
                        <li><Link href={`/notifications`}>{t("notifications")}</Link></li>
                        <li><ChangeLanguage/></li>
                        <li></li>
                        <li><Link href='/auth/logout' method="post" as="button" type="button">{t("logout")}</Link></li>
                    </ul>
                </div> : <div>
                    <Link href='/auth/new' className={'btn  mr-4'}>{t("sign_in")}</Link>
                </div>}
            </div>
        </div>
    </>
}

export default Navbar
