import handlePng from '/assets/handle.png'
import userPng from '/assets/user.png'
import passwordPng from '/assets/password.png'
import womanPng from '/assets/woman.png'
import manPng from '/assets/man.png'
import manSvg from '/assets/man.svg'
import {Link, useForm} from "@inertiajs/react";
import {useTranslation} from "react-i18next";

const Register = () => {
    const { t } = useTranslation();
    const {data, setData, post, processing, errors, transform, progress} = useForm({
        photo: '',
        first_name: '',
        last_name: '',
        about: '',
        handle: '',
        gender: '',
        birthday: '',
        password: '',
    })

    const submit = (e) => {
        e.preventDefault()
        transform((data) => ({
            ...data,
            gender: data.gender === 'on' ? 0 : 1
        }))
        post('/users')
    }

    return <>
        <form onSubmit={submit} className={'flex flex-col items-center gap-6'}>
            <div className="avatar">
                <div className="w-24 rounded-full">
                    <img
                        src={data.photo && ["image/jpeg", "image/png", "image/webp"].includes(data.photo.type) ? URL.createObjectURL(data.photo) : manSvg}/>
                </div>
            </div>
            {errors.photo && <div className={'mr-auto text-red-500'}>{errors.photo}</div>}
            <label className="input input-bordered flex items-center gap-2 w-96">
                <img
                    src={userPng}
                    alt="first name"
                />
                <input type="text" value={data.first_name} onChange={(e) => setData('first_name', e.target.value)}
                       className="grow" placeholder={t('first_name')}/>
            </label>
            {errors.first_name && <div className={'mr-auto text-red-500'}>{errors.first_name}</div>}
            <label className="input input-bordered flex items-center gap-2 w-96">
                <img
                    src={userPng}
                    alt="last name"
                />
                <input type="text" value={data.last_name}
                       onChange={(e) => setData('last_name', e.target.value)} className="grow" placeholder={t('last_name')}/>
            </label>
            {errors.last_name && <div className={'mr-auto text-red-500'}>{errors.last_name}</div>}
            <label className="input input-bordered flex items-center gap-2 w-96">
                <img
                    src={handlePng}
                    alt="at symbol"
                />
                <input type="text" value={data.handle}
                       onChange={(e) => setData('handle', e.target.value)} className="grow"
                       placeholder={t('handle')}/>

            </label>
            {errors.handle && <div className={'mr-auto text-red-500'}>{errors.handle}</div>}
            <label className="input input-bordered flex items-center gap-2 w-96">
                <img
                    src={passwordPng}
                    alt="password"
                />
                <input type="password" value={data.password}
                       onChange={(e) => setData('password', e.target.value)} className="grow"
                       placeholder={t('password')}/>

            </label>
            {errors.password && <div className={'mr-auto text-red-500'}>{errors.password}</div>}
            <textarea value={data.about}
                      onChange={(e) => setData('about', e.target.value)}
                      placeholder={t('about')}
                      className="textarea textarea-bordered textarea-lg w-96"></textarea>
            {errors.about && <div className={'mr-auto text-red-500'}>{errors.about}</div>}
            <input type="file" className="file-input w-full max-w-xs"
                   onChange={(e) => setData('photo', e.target.files[0])}/>
            <div className={'flex justify-start items-center w-96 '}>
                <h3 className={'mr-32'}>{t("gender")}:</h3>
                <label className="swap swap-flip text-9xl">
                    <input type="checkbox"
                           onChange={(e) => setData('gender', e.target.value)}/>

                    <div className="swap-on"><img
                        src={manPng}
                        alt="man"
                    /></div>
                    <div className="swap-off"><img
                        src={womanPng}
                        alt="woman"
                    /></div>
                </label>
            </div>
            <div className={'flex justify-start items-center w-96'}>
                <h3 className={'mr-10'}>{t('birthday')}:</h3>
                <input type="date" value={data.birthday}
                       onChange={(e) => setData('birthday', e.target.value)}/>

            </div>
            {errors.birthday && <div className={'mr-auto text-red-500'}>{errors.birthday}</div>}
            {progress && (
                <progress value={progress.percentage} max="100">
                    {progress.percentage}%
                </progress>
            )}

            <div className="flex gap-6">
                <button className={'btn'} type="submit" disabled={processing}>{t('sign_up')}</button>
            </div>
        </form>
    </>
}

export default Register
