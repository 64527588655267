import {useForm} from "@inertiajs/react";
import passwordPng from "/assets/password.png";
import handlePng from "/assets/handle.png";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import Register from "./Register.jsx";

const Login = () => {
    const {t} = useTranslation();
    const {data, setData, post, processing, errors} = useForm({
        handle: "",
        password: "",
    })

    const submit = (e) => {
        e.preventDefault()
        post('/auth/login')
    }


    return <>
        <form onSubmit={submit} className={'flex flex-col items-center gap-6'}>
            <label className="input input-bordered flex items-center gap-2 w-96">
                <img
                    src={handlePng}
                    alt="handle"
                />
                <input type="text" value={data.title} onChange={(e) => setData('handle', e.target.value)}
                       className="grow" placeholder={t("handle")}/>
            </label>
            <label className="input input-bordered flex items-center gap-2 w-96">
                <img
                    src={passwordPng}
                    alt="password"
                />
                <input type="password" value={data.password}
                       onChange={(e) => setData('password', e.target.value)} className="grow"
                       placeholder={t("password")}/>

            </label>
            {errors.error && <div className={'mr-auto text-red-500'}>{errors.error}</div>}
            <div className="flex gap-6">
                <button className={'btn'} type="submit" disabled={processing}>{t("sign_in")}</button>
            </div>
        </form>
    </>
}

export default Login
