import {Link, usePage} from '@inertiajs/react'
import trashPng from '/assets/trash.png'
import editPng from '/assets/sm-edit.png'
import {forwardRef} from "react";
import {useTranslation} from "react-i18next";

const TravelCard = forwardRef(({travel, with_avatar = true, with_actions = false}, ref) => {
    const {t} = useTranslation();
    const date = new Date(travel.start_at);
    const {current_user} = usePage().props;
    const isPrivateProfile = current_user?.id === travel.user_id;
    const button = () => {
        if (current_user === null) {
            return <button className="btn btn-disabled">{t("must_be_registered")}</button>
        }

        if (travel.status === 'active') {
            let text = t("join")
            if(travel.request_status === 0) text = t("already_request")
            if(travel.request_status === 1) text = t("you_refused")
            if(travel.request_status === 2) text = t("already_joined")

            if (!isPrivateProfile) {
                return <Link href={`/memberships/demand`} method="post" as="button" data={{travel_id: travel.id, user_id: travel.user_id, method: "request"}} className={`btn ${[0, 1, 2].includes(travel.request_status) ? "btn-disabled" : ""}`}>{text}</Link>
            } else {
                return ""
            }
        } else {
            return <button className="btn btn-disabled">{t("finished")}</button>
        }
    }

    return <div className="card bg-base-100 w-96 shadow-md mb-4" ref={ref}>
        <div className="card-body">
            <div className={"flex justify-between"}>
                <h2 className="card-title">
                    <Link href={`/travels/${travel.id}`} className="link link-info">{travel.title}</Link>
                </h2>
                {with_avatar ? <Link href={`/users/${travel.user_id}`}>
                    {travel.user_photo_url ? <div className="avatar">
                        <div className="w-24 rounded-full">
                            <img src={travel.user_photo_url}/>
                        </div>
                    </div> : <div className="avatar placeholder">
                        <div className="bg-neutral text-neutral-content w-24 rounded-full">
                            <span
                                className="text-xs">{travel.user_first_name.charAt(0) + " " + travel.user_last_name.charAt(0)}</span>
                        </div>
                    </div>}
                </Link> : ""}
                {isPrivateProfile && with_actions ? <div className={'flex'}>
                    <Link href={`/travels/${travel.id}/edit`} as='button'> <img src={editPng} alt="edit travel info"
                                                                                className={'mr-2'}/></Link>
                    <Link href={`/travels/${travel.id}`} method='delete' as='button'> <img src={trashPng}
                                                                                           alt="delete travel"/></Link>
                </div> : ""}
            </div>
            <p className={'break-words'}>{travel.description}</p>
            <div className="card-actions justify-between items-center">
                <span>{t("travel_start_date")}: {date.toLocaleString('ru-RU', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit'
                })}</span>
                {}
            </div>
            {button()}
        </div>
    </div>;
})

export default TravelCard;
