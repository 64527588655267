import Navbar from "../components/Navbar.jsx";
import {useTranslation} from "react-i18next";
import {usePage} from "@inertiajs/react";
import {useEffect} from "react";
import {SnackbarProvider} from "notistack";

const MainLayout = ({children}) => {
    const {t, i18n} = useTranslation();
    const {locale} = usePage().props;

    useEffect(() => {
        if (locale) {
            i18n.changeLanguage(locale);
        }
    }, [locale]);

    return <>
        <SnackbarProvider autoHideDuration={5000}>
            <main className={'container mx-auto'}>
                <div
                    className="absolute inset-x-0 top-10 z-40  w-1/2 lg:w-1/4 mx-auto warning-message opacity-0 transition-opacity duration-500">
                    <div role="alert" className="alert alert-warning">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6 shrink-0 stroke-current"
                            fill="none"
                            viewBox="0 0 24 24">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"/>
                        </svg>
                        <span>{t("subscribe_error")}</span>
                    </div>
                </div>
                <Navbar/>
                <article>{children}</article>
            </main>
        </SnackbarProvider>
    </>
}

export default MainLayout
