import TravelCard from "../components/TravelCard.jsx";
import editPng from '/assets/md-edit.png'

import {Link, usePage} from "@inertiajs/react";
import calculateAge from "../../helpers/date.js";
import {useTranslation} from "react-i18next";
const show = ({user, travels}) => {
    const {t} = useTranslation()
    const { current_user } = usePage().props

    return <>
        <div className={'flex flex-col items-center'}>
            <div className="card bg-base-100 w-96 shadow-md mb-8">
                <div className="card-body">
                    <div className={'flex flex-col items-center gap-6'}>
                        {user.photo_url ? <div className="avatar">
                            <div className="w-24 rounded-full">
                                <img src={user.photo_url}/>
                            </div>
                        </div> : <div className="avatar placeholder">
                            <div className="bg-neutral text-neutral-content w-24 rounded-full">
                                <span
                                    className="text-xs">{user.first_name.charAt(0) + " " + user.last_name.charAt(0)}</span>
                            </div>
                        </div>}
                        {current_user?.id === user.id ? <Link href={`/users/${user.id}/edit`}> <img src={editPng} alt="edit user info"
                                                                                    className={'absolute right-10 top-16'}/></Link> : ""}
                        <div className={'flex flex-col items-center'}>
                            <h2 className={'text-3xl font-bold leading-10'}>{user.first_name}</h2>
                            <h2 className={'text-3xl font-bold'}>{user.last_name}</h2>
                        </div>

                        <div className={'flex justify-evenly items-center w-full'}>
                            <h2 className={'text-1xl'}>{user.gender === 'man' ? t("man") : t("woman")}</h2>
                            <h2 className={'text-1xl text-sky-400'}>@{user.handle}</h2>
                            <h2 className={'text-1xl'}>{calculateAge(user.birthday)} {t("years_old")}</h2>
                        </div>
                        {current_user?.id === user?.id ? <Link href={'/travels/new'} className={'btn'}>{t("create_journey")}</Link> : ""}
                    </div>

                </div>
            </div>
            <article className={'prose'}>
                <h2>{t("about_me")}</h2>
                <p>{user.about}</p>
            </article>
            <article className={'prose mt-8'}>
                {travels.length > 0 ? <h2>{t("my_travels")}</h2> : <h2>{t("haven_not_traveled")}</h2>}
            </article>
            <div className={'flex flex-col items-center'}>
                {travels.map(travel => <TravelCard key={travel.id} travel={travel} with_avatar={false} with_actions={true}/>)}
            </div>
        </div>
    </>
}

export default show
